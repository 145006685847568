.ReportsBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
}

.ReportsBox button {
  background-color: #4c9fb1;
  color: white;
  border: none;
  padding: 8px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.1s linear;
}

.ReportsBox button:hover {
  background-color: #207184;
}

@media screen and (max-width: 400px) {
  .ReportsBox h3 {
    font-size: 10px;
  }
  .ReportsBox button {
    font-size: 10px;
  }
}
